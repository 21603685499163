import cn from 'classnames';
import React, { useState } from 'react';
import Application from '@/dmPortal/domain/Application';
import RefreshApplicationBtn from '@/dmPortal/ui/common/RefreshApplicationBtn';
import styles from '@/dmPortal/ui/components/Applications/ApplicationCard/StateButton/stateButton.scss';
import Toggle from '@dealroadshow/uikit/core/components/Toggle';
import Tooltip from '@dealroadshow/uikit/core/components/Tooltip';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import textStyles from '@dealroadshow/uikit/core/styles/text.scss';
import { useNavigationContext } from '@/dmPortal/application/Layout/NavigationContext';

interface IProps {
  app: {
    tenant: string,
    state: string,
  },
  index?: number,
}

const StateButton = (props: IProps) => {
  const [isActivated, setIsActivated] = useState(Application.isActivated(props.app.state));
  const { disableApplication, enableApplication, isRefreshing } = useNavigationContext();

  const getStateTitle = (state) => {
    if (Application.isActivated(state) && !Application.statusCanBeChanged(state)) {
      return <span className={ cn(textStyles.smallTitle, styles.statusTitle) }>Activated</span>;
    }
    if (Application.notActivated(state)) {
      return <span className={ cn(textStyles.smallTitle, styles.statusTitle, styles.statusToggleActive) }>Off</span>;
    }
    if (Application.isActivated(state) && Application.statusCanBeChanged(state)) {
      return <span className={ cn(textStyles.smallTitle, styles.statusTitle, styles.statusToggleActive) }>On</span>;
    }
    if (Application.hasNoAccess(state)) {
      return <span className={ cn(textStyles.smallTitle, styles.statusTitle, styles.noAccess) }>No access</span>;
    }
    if (Application.hasNoInformation(state)) {
      return <span className={ cn(textStyles.smallTitle, styles.statusTitle) }>Not available</span>;
    }

    return undefined;
  };

  const handleOnChange = () => {
    if (Application.isActivated(props.app.state)) {
      setIsActivated(false);
      disableApplication(props.app.tenant);
    } else {
      setIsActivated(true);
      enableApplication(props.app.tenant);
    }
  };

  return (
    <>
      {
        Application.statusCanBeChanged(props.app.state) && (
          // @ts-ignore
          <Toggle
            className={ styles.statusToggle }
            dataTest={ `${ props.app.tenant }CardToggle` }
            checked={ isActivated }
            onChange={ handleOnChange }
          />
        )
      }
      {
        Application.hasNoInformation(props.app.state) && !isRefreshing[props.index] && (
          <div className={ styles.refreshBtnWrp }>
            <Tooltip
              placement="bottom"
              content="A connection error has occurred. Please click to refresh."
            >
              <RefreshApplicationBtn
                className={ styles.refreshBtn }
                tenant={ props.app.tenant }
              />
            </Tooltip>
          </div>
        )
      }
      {
        isRefreshing[props.index]
          ? (
            <Spinner
              className={ styles.spinner }
              overlay
              isAbsolute
            />
          )
          : getStateTitle(props.app.state)
      }
    </>
  );
};

export default React.memo(StateButton);
