import cn from 'classnames';
import React from 'react';
import { useNavigationContext } from '@/dmPortal/application/Layout/NavigationContext';
import IconRotate from '@dealroadshow/uikit/core/components/Icon/IconRotate';
import styles from './refreshApplicationBtn.scss';

interface IProps {
  tenant: string,
  className: string,
}

function RefreshApplicationBtn(props: IProps) {
  const { refreshApplication } = useNavigationContext();
  return (
    <button
      type="button"
      data-test={ `${ props.tenant }RefreshButton` }
      className={ cn(styles.btn, props.className) }
      onClick={ () => refreshApplication(props.tenant) }
    >
      <IconRotate />
    </button>
  );
}

export default RefreshApplicationBtn;
